<template>
	<div class="grid crud-demo">
        <p>We're three co-founders.</p>
		<div class="flex col-12" style="flex-wrap: wrap!important;">

<div class="surface-card p-4 shadow-2 border-round">
    <div class="text-3xl font-medium text-900 mb-3">Charles Nurdin</div>
    <div class="font-medium text-500 mb-3">
        Business and Product
    </div>
    <div style="min-height: 150px;position:relative;" class="">
Charles is a McKinsey alumn and cofounded and successfully exited Amplify, a
sustainable finance fintech. He is bringing together a team to create the first
B2C career coaching platform in Europe (something which, strangely enough,
doesn't exist yet).<br>
    </div>
<div class="justify-center linkedin">
<a href="https://www.linkedin.com/in/charles-nurdin-8a617455/"><i class="pi pi-linkedin"></i></a>
</div>
</div>

<div class="surface-card p-4 shadow-2 border-round">
    <div class="text-3xl font-medium text-900 mb-3">Michelle Kim</div>
    <div class="font-medium text-500 mb-3">
        Sales and Marketing
    </div>
    <div style="min-height: 150px;position:relative;" class="">
Michelle is a Growth Associate at Stripe. She has a Bachelors in Journalism and
History from Northwestern University, and previously worked at Bloomberg,
Discovery and CNN. She is very outgoing and loves to network - be sure to chat
her up!<br>
    </div>
<div class="justify-center linkedin">
<a href="https://www.linkedin.com/in/michelleyeareekim/"><i class="pi pi-linkedin"></i></a>
</div>
</div>

<div class="surface-card p-4 shadow-2 border-round">
    <div class="text-3xl font-medium text-900 mb-3">Daniel Ratke</div>
    <div class="font-medium text-500 mb-3">
        Tech
    </div>
    <div style="min-height: 150px;position:relative;" class="">
Daniel is cofounder of Aqarios, a quantum computing spinoff from LMU Munich. He
holds an M.Sc. in Computer science from LMU Munich, has researched and published
in the areas of reinforcement learning and quantum computing and has years of
experience in software development.<br>
    </div>
<div class="justify-center linkedin">
<a href="https://www.linkedin.com/in/daniel-ratke/"><i class="pi pi-linkedin"></i></a>
</div>
</div>

		</div>
	</div>

</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.surface-card {
  flex: 1;
  margin: 10px;
  padding-bottom: 30px!important;
  position: relative;
  min-width: 240px;
}
.linkedin {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  left: 49%;
}
</style>
